h1 { font-size:36px; font-weight:bold; margin-bottom:20px; }
h2 { font-size:30px; font-weight:bold; margin-bottom:20px; }
h3 { font-size:28px; font-weight:bold; margin-bottom:20px; }
h4 { font-size:22px; font-weight:bold; margin-bottom:20px; }
p { margin-bottom:20px; }
hr { margin-bottom:20px; }



.light {
    background-color:  rgb(255, 255, 255) !important;
}

.dark {
    background-color: rgb(15 23 42) !important;
}

.icon:hover { box-shadow: 0 0 11px rgba(33,33,33,.2); }
.icon:hover .grow { font-size:22px; }
.text-header-gradient:hover { color:#0f0f0f; }

#craft, #craft-c, #craft-r, #craft-a, #craft-f, #craft-t, #context-window,
#validate, #validate-i, #validate-n, #validate-g, #enhance, #enhance-ai,
#zero-single-few-shot, #shot-zero, #shot-single, #shot-few, #shot-why-important {
    scroll-margin-top: 4vh;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.temporary-bounce {
    -webkit-animation-iteration-count: 9;
    animation-iteration-count: 9;
}

.temporary-bounce-short {
    -webkit-animation-iteration-count: 3;
    animation-iteration-count: 3;
}

.light-image-textual {
    filter: invert(1%) sepia(5%) saturate(0%) hue-rotate(76deg) brightness(105%) contrast(91%);
}
.dark-image-textual {
    filter: invert(100%) sepia(99%) saturate(1%) hue-rotate(108deg) brightness(120%) contrast(100%);
}

.stroke-white .svg-stroke-dark-light {
    stroke: #ffffff;
}
.stroke-white .svg-stroke-dark-light-icon {
    stroke: #101625;
}

.stroke-gray .svg-stroke-dark-light {
    stroke: #484848;
}
.stroke-gray .svg-stroke-dark-light-icon {
    stroke: #101625;
}

.font-certificate-name {
    font-family: 'Great Vibes', cursive;
}
.download {
    width: 1123px;
    height: 794px;
}
