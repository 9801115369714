@import url(tailwind.css);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);

* {
    transition: .3s linear;
    font-family: Poppins, sans-serif;
    outline: transparent solid 2px;
    outline-offset: 2px
}

html {
    scroll-behavior: smooth
}

img {
    user-select: none;
    pointer-events: none
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0
}

input[type=number] {
    -moz-appearance: textfield
}

.cover-gradient {
    background: linear-gradient(169.4deg, rgba(57, 132, 244, .04) -6.01%, rgba(12, 211, 255, .04) 36.87%, rgba(47, 124, 240, .04) 78.04%, rgba(14, 101, 232, .04) 103.77%)
}

.cover-gradient-2 {
    background: linear-gradient(169.4deg, rgba(57, 132, 244, .1) -6.01%, rgba(12, 211, 255, .1) 36.87%, rgba(47, 124, 240, .1) 78.04%, rgba(14, 101, 232, .1) 103.77%)
}

.bg-blue-gradient, .text-gradient {
    background: linear-gradient(136.91deg, #468ef9 -12.5%, #0c66ee 107.5%)
}

.text-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent
}

.slide-enter-active {
    -moz-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in
}

.slide-leave-active {
    -moz-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    transition-duration: .3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1)
}

.slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden
}

.slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0
}

.text-header-gradient {
    background: #3984f4;
    background: linear-gradient(169.4deg, #3984f4 -6.01%, #0cd3ff 36.87%, #2f7cf0 78.04%, #0e65e8 103.77%);
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.text-header-gradient-warning {
    background: #f44336;
    background: linear-gradient(169.4deg, #f44336 -6.01%, #ff5722 36.87%, #e53935 78.04%, #d32f2f 103.77%);
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.bg-partner {
    background: url("../img/partner/background.png") center/cover
}

.bg-trading-tools {
    background: url("../img/bg-trading-tools.webp") center/cover
}

.max-h-0 {
    max-height: 0
}