@keyframes fadeBorder {
  0% {
    width:100%;
  }
  100% {
    width:0;
  }
}

.animate-fade-border {
  &.animate {
    animation: fadeBorder 10s backwards;
  }
}